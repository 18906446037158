.container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
    grid-gap: 50px 30px; /*space between rows, then columns*/
    grid-auto-flow: row;
}

.slot_container {
    display: flex;
    justify-content: space-around;
}

.emptyslot, .game {
    min-height: 18.75rem;
    max-width: 44rem;
    min-width: 32rem;
    width: 100%;
}

#filters {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

#filters > * {
    padding: 0.25rem 0.5rem;
    margin: 0rem 0.5rem;
    font-size: 16pt;
    background: rgba(0,0,0,0);
}

#filters > .filter {
    border-radius: 0.5rem;
    min-width: 6.25rem;
    text-align: center;
    border: none;
    color: white;
    text-decoration: none;
}

#selected_filter {
    background: rgb(113, 54, 138);
}

#footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 4.5rem;
}

#footer > div {
    text-align: center;
    font-size: 16pt;
    position: relative;
    top: 0.25rem;
}

.emptyslot {
    border: 2px dashed white;
    border-radius: 15px;
    text-align: center;
    color: white;
}

@media only screen and (max-device-width: 800px) {
    .container {
    }
}
