@import url(https://fonts.googleapis.com/css2?family=Alegreya&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Goldman:wght@700&display=swap);
:root {
    --background-main: #2f3136;
    --background-secondary: #4f545c;
    --background-tertiary: #202225;
    --background-accent: #40444b;
    --highlight: rgb(113, 54, 138); /*matteo purple™*/
    --accent-red: #f04747;
    --accent-green: rgb(67, 181, 129);
}

body {
    background-image: url(/static/media/naturalblack.9e272f4d.png);
}
/* Background pattern from Toptal Subtle Patterns */

* {
    font-family: 'Alegreya', serif;
    color: white;
}

h2 {
    text-align: center;
}

.link{
    position: relative;
    top: 10px; 
}

.h1 {
    margin: auto;
    width: 45%;
    color: white;
}

.page_header {
    color: white;
    font-family: 'Goldman', cursive;
    text-decoration: none;
}

#header {
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#header .page_header {
    margin: auto;
}

#links {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

#link_div {
    text-align: right;
    position: absolute;
    top: 1rem;
    right: 2rem;
    display: flex;
}

#nav_links {
    position: absolute;
    top: 1rem;
    left: 2rem;
}

.github_logo, .twitter_logo, .patreon_container {
    height: 2rem;
    width: 2rem;
    margin-left: 0.75rem;
}

.patreon_container {
    border-radius: 1rem;
    background: #FF424D;
}

.patreon_logo {
    box-sizing: border-box;
    padding: 0.35rem;
    height: 2rem;
    width: 2rem;
    position: relative;
    left: 0.1rem;
    bottom: 0.05rem;
}

a {
    background-color: transparent;
    text-decoration: underline;
}

a:link, a:visited {
    color: lightblue;
}

a:hover {
    color: white;
}

img.emoji {
   height: 1em;
   width: 1em;
   margin: 0 .05em 0 .1em;
   vertical-align: -0.1em;
}

@media only screen and (max-width: 800px) {
    #link_div, #nav_links {
        position: static;
        margin-top: 0.5rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }
}
.container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
    grid-gap: 50px 30px; /*space between rows, then columns*/
    grid-auto-flow: row;
}

.slot_container {
    display: flex;
    justify-content: space-around;
}

.emptyslot, .game {
    min-height: 18.75rem;
    max-width: 44rem;
    min-width: 32rem;
    width: 100%;
}

#filters {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

#filters > * {
    padding: 0.25rem 0.5rem;
    margin: 0rem 0.5rem;
    font-size: 16pt;
    background: rgba(0,0,0,0);
}

#filters > .filter {
    border-radius: 0.5rem;
    min-width: 6.25rem;
    text-align: center;
    border: none;
    color: white;
    text-decoration: none;
}

#selected_filter {
    background: rgb(113, 54, 138);
}

#footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 4.5rem;
}

#footer > div {
    text-align: center;
    font-size: 16pt;
    position: relative;
    top: 0.25rem;
}

.emptyslot {
    border: 2px dashed white;
    border-radius: 15px;
    text-align: center;
    color: white;
}

@media only screen and (max-device-width: 800px) {
    .container {
    }
}

.game {
    text-align: center;
    display: flex;
    flex-direction: column;
    background:var(--background-main);
    border: 4px solid;
    border-radius: 4px;
    border-color: var(--highlight);
    border-top: none;
    border-right: none;
    border-bottom: none;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    width: 100%;
    min-width: 32rem;
    max-width: 44rem;
}

.header {
    width: 100%;
    background-color: var(--background-secondary);
    border-top-right-radius: 4px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    display: flex;
    justify-content: space-between
}

.header > div {
    margin: 0.3rem 0.5rem;
}

.body {
    margin: 0.5rem;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-areas:
      "teams teams info" "players players info" "update update update";
    grid-template-rows: minmax(5.75rem, -webkit-min-content) minmax(4rem, -webkit-min-content) minmax(4rem, -webkit-min-content);
    grid-template-rows: minmax(5.75rem, min-content) minmax(4rem, min-content) minmax(4rem, min-content);
    grid-row-gap: 0.5rem;
    grid-column-gap: 0.75rem;
}

.teams {
    grid-area: teams;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 95%;
    max-width: 100%;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
}

.team {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0.25rem 0rem;
}

.team_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.info {
    grid-area: info;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: #4f545c;
    padding: 0.75rem 0rem;
    border-radius: 4px;
}

.batting {
    font-size: 10pt;
    text-align: left;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    margin: 0.3rem 0.5rem;
}

.leagueoruser {
    font-size: 10pt;
    text-align: right;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    margin: 0.3rem 0.5rem;
}

.footer {
    display: flex;
    justify-content: space-between;
}

.outs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
}

.outs_title {
    font-weight: bolder;
}

.outs_count {
    display: flex;
}

.out {
    height: 20px;
}

.team_name, .score {
    font-size: 25px
}

.score {
    background: var(--background-secondary);
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 10px;
}

.players {
    grid-area: players;
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    grid-template-rows: auto auto;
    grid-column-gap: 0.5rem;
    margin-left: 0.3rem;
}

.players > div {
    margin: 0.25rem 0rem;
}

.player_type {
    text-align: end;
    font-weight: bolder;
    display: inline-block;
    vertical-align: middle;
}

.player_name {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
    white-space: nowrap;
    width: 100%;
}

.update {
    grid-area: update;
    min-height: 3.5rem;
    padding: 0rem 0.75rem;
    height: 100%;
    background: var(--background-secondary);
    border-radius: 4px;
    align-items: center;
    display: flex;
    justify-content: flex-start;
}

.update_emoji {
    margin-right: 0.75rem;
    margin-left: 2px;
}

.update_text {
    text-align: start;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.field {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.base {
    height: 60px;
}

.field > .base {
    margin-bottom: -25%
}

@media only screen and (max-device-width: 800px) {
    .game {
        font-size: 15pt;
    }

    .batting, .leagueoruser {
        font-size: 14pt;
        padding: 3px;
    }

    .team_name, .score {
        font-size: 25px
    }

    .players {
        font-size: 20px;
    }

    .update_emoji, .update_text {
        display: inline;
        font-size: 20px;
    }
}
#game_container {
	margin-top: 3rem;
	margin-left: 1rem;
	margin-right: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.history_box {
	width: 100%;
	margin-top: 3rem;
	padding: 1rem;
	padding-top: 0.5rem;
	background: var(--background-main);
	border-radius: 0.25rem;
    width: 100%;
    min-width: 32rem;
    max-width: 44rem;
    box-sizing: border-box;
    border: 4px solid;
    border-radius: 4px;
    border-color: var(--highlight);
    border-top: none;
    border-right: none;
    border-bottom: none;
}

.history_title {
  font-size: 14pt;
}

.history_update {
	height: 4rem;
	margin: 0.5rem;
}
.cl_table_header > .cl_subleague_bg {
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
	padding-top: 1rem;
}

.cl_league_structure_table .cl_table_row:last-child .cl_subleague_bg {
	border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
	padding-bottom: 1rem;
}

.cl_league_structure_table .cl_table_row:last-child .cl_division_delete {
	margin-bottom: 0.5rem;
}

input {
	border: none;
	border-radius: 1rem;
	height: 2rem;
	padding-left: 1rem;
	background: var(--background-secondary);
	font-size: 14pt;
}

input:focus {
	outline: none;
}

input[type="number"] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}

.cl_league_main {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 3rem;
}

.cl_league_name {
	margin-top: 1rem;
}

.cl_league_options, .cl_league_structure, .cl_confirm_box {
	display: flex;
	background: var(--background-tertiary);
	flex-direction: column;
	max-width: 100%;
	border-radius: 1rem;
	padding-top: 1.5rem;
}

.cl_confirm_box {
	min-width: 55rem;
	padding: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20pt;
}

.cl_league_options {
	align-items: center;
}

.cl_league_structure, .cl_subleague_add_align {
	display: flex;
	align-items: center;
	justify-content: center;
	width: -webkit-min-content;
	width: -moz-min-content;
	width: min-content;
}

.cl_league_structure {
	margin-top: 1rem;
}

.cl_league_structure_table {
	display: table;
	margin-right: 1rem;
}

.cl_headers, .cl_table_row {
	display: table-row;
	height: -webkit-min-content;
	height: -moz-min-content;
	height: min-content;
}

.cl_table_header, .cl_delete_filler, .cl_delete_box, .cl_division_cell {
	display: table-cell;
	height:100%;
}

.cl_delete_box {
    vertical-align: middle;
}

.cl_league_structure_scrollbox {
	max-width: 100%;
	overflow-y: scroll;
}

 /* Hide scrollbar for Chrome, Safari and Opera */
.cl_league_structure_scrollbox::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.cl_league_structure_scrollbox {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.cl_subleague_add_align{
	margin-left: 1.5rem;
	padding-right: 1.5rem;
}

.cl_subleague_header {
	display: flex;
	width:100%;
	align-items: center;
	justify-content: space-between;
} 

.cl_subleague_bg {
	background: var(--background-main);
	padding: 0.5rem 1rem;
	margin: 0rem 0.5rem;
	min-width: 22rem;
	height: 100%;
	box-sizing: border-box;
}

.cl_subleague_name {
	flex-grow: 1;
	margin-right: 0.5rem;
}

.cl_division_name_box {
	width: 100%;
}

.cl_division_name {
	margin-bottom: 0.5rem;
	width: 95%;
}

.cl_newteam_name {
	width: 95%;
}

.cl_division {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	border-radius: 0.5rem;
	background: var(--background-accent);
}

.cl_team, .cl_team_add {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 0.4rem 0rem;
}

.cl_team_name {
	font-size: 14pt;
	padding: 0 0.5rem;
	overflow: hidden;
	text-overflow: ellipsis;
}

.cl_team_add {
	display: flex;
	flex-direction: column;
	margin-bottom: 0rem;
}

.cl_search_list {
	width: 95%;
	margin-top: 0.6rem;
	padding: 0.5rem;
	background: var(--background-tertiary);
	border-radius: 0.5rem;
}

.cl_search_result {
	padding: 0.2rem 0.4rem;
	border-radius: 0.5rem;
}

.cl_search_result:hover {
	background: var(--background-main);
}

.cl_league_options {
	padding: 1rem;
	margin-top: 1.5rem;
	width: 55rem;
	box-sizing: border-box;
}

.cl_option_main {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 100%;
}

.cl_option_submit_box {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.cl_option_box {
	margin: 1rem;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}

.cl_option_label, .cl_option_err, .cl_structure_err {
	margin: 0.25rem;
}

.cl_option_err, .cl_structure_err {
	color: var(--accent-red);
}

.cl_option_err {
	min-height: 1.5rem;
	margin-bottom: -0.5rem;
	margin-top: 0.25rem;
}

.cl_structure_err {
	margin-bottom: -0.5rem;
}

.cl_structure_err_div {
	margin-top: -0.25rem;
	margin-bottom: 0;
}

.cl_structure_err_team {
	margin-top: -0.5rem;
	margin-bottom: 0;
	width: 95%;
	padding-left: 0.5rem;
}

.cl_team_name_err {
	color: var(--accent-red);
}

.cl_structure_err_teams {
	width: 98%;
}

.cl_subleague_add_filler, .cl_division_add_filler {
	width: 2rem;
	height: 2rem;
}

/* button styles */

button > .emoji {
	margin: 0;
	width: 1rem;
	height: 1rem;
}

.cl_subleague_delete, .cl_team_delete, .cl_division_delete, .cl_subleague_add, .cl_division_add {
	padding: 0;
	width: 2rem;
	height: 2rem;
	border: none;
	border-radius: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cl_subleague_delete, .cl_team_delete, .cl_division_delete {
	background: var(--accent-red);
}

.cl_subleague_add, .cl_division_add {
	background: var(--accent-green);
}

.cl_subleague_add, .cl_subleague_add_filler {
	position: relative;
	top: 1.5rem;
}

.cl_division_add, .cl_division_add_filler {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
}

.cl_delete_filler {
	min-width: 3rem;
}

.cl_option_submit {
	padding: 1rem 2rem;
	height: 2rem;
	border: none;
	border-radius: 0.75rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--accent-green);
	font-size: 14pt;
}
