#game_container {
	margin-top: 3rem;
	margin-left: 1rem;
	margin-right: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.history_box {
	width: 100%;
	margin-top: 3rem;
	padding: 1rem;
	padding-top: 0.5rem;
	background: var(--background-main);
	border-radius: 0.25rem;
    width: 100%;
    min-width: 32rem;
    max-width: 44rem;
    box-sizing: border-box;
    border: 4px solid;
    border-radius: 4px;
    border-color: var(--highlight);
    border-top: none;
    border-right: none;
    border-bottom: none;
}

.history_title {
  font-size: 14pt;
}

.history_update {
	height: 4rem;
	margin: 0.5rem;
}